import noImage from './images/icons/noImage_1.png';
import idle from './images/icons/idle_on.png';
import registered from './images/icons/registered_on.png';
import active from './images/icons/active_on.png';
import discontinued from './images/icons/discontinued_on.png';
import unknown from './images/icons/unknown.png';
import product from './images/icons/product.png';
import service from './images/icons/service.png';
import unit from './images/icons/unit.png';
import weight from './images/icons/weight.png';
import other from './images/icons/other.png';

import icon_undefined from './images/icons/icon_undefined.png';
import icon_gender_notSpecified from './images/icons/icon_gender_notSpecified.png';
import icon_female from './images/icons/icon_female.png';
import icon_male from './images/icons/icon_male.png';


export default class BackOfficeConfig {

    //static KALOPSIA_URL_DEF = "https://cartos.kalopsia.pro:8443";
    static KALOPSIA_URL_DEF = "https://cartos.kalopsia.pro/backoffice";
    static KALOPSIA_API_URL_DEF = "https://cartos.kalopsia.pro";

    static LOCATION_TYPES_DEF = {
        0: "Undefined",
        1: "Independent",
        2: "Mall",
        3: "Mobile",
    }

    static LOCATION_RELATION_DEF = {
        0: "Undefined",
        1: "Own",
        2: "Franchise",
        3: "Contract",
        9: "Other",
    }

    static LOCATION_STATUS_DEF = {
        0: {
            "name": "Registered",
            "image": registered
        },
        1: {
            "name": "Active",
            "image": active
        },
        2: {
            "name": "Suspended",
            "image": discontinued
        }
    }

    static LOCATION_SUPPLY_FREQUENCY_DEF = {
        0: "Undefined",
        1: "Daily",
        2: "2 Days",
        3: "3 Days",
        4: "4 Days",
        5: "5 Days",
        6: "6 Days",
        7: "Weekly",
        8: "2 Weeks",
        9: "3 Weeks",
        10: "Monthly"
    }

    static LOCATION_GROUPS_TYPES_DEF = {
        0: "Undefined",
        1: "Region",
        2: "Management",
        3: "Delivery",
        4: "Landlord",
        9: "Other"
    }
    
    static LOCATION_GROUPS_STATUS_DEF = {
        0: {
            "name": "Registered",
            "image": registered
        },
        1: {
            "name": "Active",
            "image": active
        },
        2: {
            "name": "Suspended",
            "image": discontinued
        }
    }

    static WEEKDAYS_EN_DEF = {
        0: "Undefined",
        1: "Monday",
        2: "Tuesday",
        3: "Wednsday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        7: "Sunday",    
    }
    
    static WEEKDAYS_RO_DEF = {
        0: "Nedefinit",
        1: "Luni",
        2: "Marti",
        3: "Miercuri",
        4: "Joi",
        5: "Vineri",
        6: "Sambata",
        7: "Duminica",    
    }

    static CURRENCIES_DEF = {
        0: "RON",
        1: "EUR",
        2: "USD",
        9: "Undefined",
    }

    static USER_TYPES_DEF = {
        0: "No User",
        1: "No User",
        5: "Client",
        15: "LocationFranchise",
        25: "LocationEmployee",
        26: "LocationAdmin",
        27: "POS",
        29: "Location",
        30: "LocationGroup",
        31: "HQ_ro",
        32: "HQ_Marketing",
        33: "HQ_Accounting",
        34: "HQ_Platform",
        40: "Admin",
        50: "SuperAdmin",
    }

    static USER_STATUS_DEF = {
        0: "Registered",
        1: "Active",
        2: "Suspended",
    }

    static USER_STATUS_WITH_ICONS_DEF = {
        0: {
            "name": "Registered",
            "image": registered
        },
        1: {
            "name": "Active",
            "image": active
        },
        2: {
            "name": "Suspended",
            "image": discontinued
        }
    }

    static CATEGORY_OPERATION_TYPES = {
        0: "FIFO",
        1: "LIFO",
        3: "FEFO",
        9: "Undefined"
    }

    static GENDER_TYPES_WITH_ICONS_DEF = {
        0: {
            "name": "Not Specified",
            "image": icon_gender_notSpecified
        },
        1: {
            "name": "Female",
            "image": icon_female
        },
        2: {
            "name": "Male",
            "image": icon_male
        },
        9: {
            "name": "Undefined",
            "image": icon_undefined
        }
    }

    static FAMILY_LEVELS_DEF = {
        0: "Master",
        1: "Member",
        9: "Unknown",
    }

    static COMPANY_LEVELS_DEF = {
        0: "Master",
        1: "Member",
        9: "Unknown",
    }

    static EMPLOYEE_TYPES_DEF = {
        0: "Employee",
        1: "Coordinator",
        2: "Owner",
        3: "OrdersEmployee"
    }

    static PRODUCT_STATUS_WITH_ICONS_DEF = {
        0: {
            "name": "Registered",
            "image": registered
        },
        1: {
            "name": "Idle",
            "image": idle
        },
        2: {
            "name": "Active",
            "image": active
        },
        9: {
            "name": "Discontinued",
            "image": discontinued
        },
        10: {
            "name": "Unknown",
            "image": unknown
        }
    }

    static PRODUCT_TYPES_WITH_ICONS_DEF = {
        0: {
            "name": "Product",
            "image": product
        },
        1: {
            "name": "Service",
            "image": service
        },
        9: {
            "name": "Undefined",
            "image": unknown
        },
    }

    static PRODUCT_UNIT_TYPES_WITH_ICONS_DEF = {
        0: {
            "name": "Unit",
            "image": unit
        },
        1: {
            "name": "Weight",
            "image": weight
        },
        3: {
            "name": "Other",
            "image": other
        },
        9: {
            "name": "Undefined",
            "image": unknown
        },
    }

    static PRODUCT_COMBO_PRODCUT_TYPES_DEF = {
        0: "Included in combo",
        1: "Price from product",
        2: "Set value"
    }

    
    //Base & API
    static get BASE_URL() { return this.KALOPSIA_URL_DEF };
    static get BASE_API_URL() { return this.KALOPSIA_API_URL_DEF };
    static get BACKOFFICE_URL() {return this.BASE_URL +'/backoffice/'};
    static get DASHBOARD_URL() {return this.BASE_URL +'/Dashboard/'};
    static get API_URL() {return this.KALOPSIA_API_URL_DEF };
    //static get BASE_API_URL() {return 'api/1.0/'};

    static get LOCATION_TYPES() { return this.LOCATION_TYPES_DEF};
    static get LOCATION_RELATION() { return this.LOCATION_RELATION_DEF};
    static get LOCATION_STATUS() { return this.LOCATION_STATUS_DEF};
    static get LOCATION_SUPPLY_FREQUENCY() { return this.LOCATION_SUPPLY_FREQUENCY_DEF};
    
    static get LOCATION_GROUPS_TYPES() { return this.LOCATION_GROUPS_TYPES_DEF};
    static get LOCATION_GROUPS_RELATION() { return this.LOCATION_GROUPS_RELATION_DEF};
    static get LOCATION_GROUPS_STATUS() { return this.LOCATION_GROUPS_STATUS_DEF};

    static get WEEKDAYS_EN() { return this.WEEKDAYS_EN_DEF};
    static get WEEKDAYS_RO() { return this.WEEKDAYS_RO_DEF};

    static get CURRENCIES() { return this.CURRENCIES_DEF};

    static get USER_TYPES() { return this.USER_TYPES_DEF};
    static get USER_STATUSES() { return this.USER_STATUS_DEF};
    static get USER_STATUSES_WITH_ICONS() { return this.USER_STATUS_WITH_ICONS_DEF};
    static get GENDER_TYPES_WITH_ICONS() { return this.GENDER_TYPES_WITH_ICONS_DEF};

    static get FAMILY_LEVELS() { return this.FAMILY_LEVELS_DEF};
    static get COMPANY_LEVELS() { return this.COMPANY_LEVELS_DEF};

    static get EMPLOYEE_TYPES() { return this.EMPLOYEE_TYPES_DEF};
   
    static get PRODUCT_STATUSES_WITH_ICONS() { return this.PRODUCT_STATUS_WITH_ICONS_DEF};
    static get PRODUCT_TYPES_WITH_ICONS() { return this.PRODUCT_TYPES_WITH_ICONS_DEF};
    static get PRODUCT_UNIT_TYPES_WITH_ICONS() { return this.PRODUCT_UNIT_TYPES_WITH_ICONS_DEF};

    static get PRODUCT_COMBO_PRODCUT_TYPES() { return this.PRODUCT_COMBO_PRODCUT_TYPES_DEF};
}
